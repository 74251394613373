import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GuestForm from "../../components/GuestForm";
import Layout from "../../components/Layout";
import { BASE_API_URL } from "../../utils/constants";
import useLivlyGuest, {
  DelegateServiceTypes,
  getDelegateServiceQuery,
  GuestRequest,
} from "@/context/GuestProvider";
import useLivlyUser from "@/context/UserProvider";
import { useEffect } from "react";

function usePostGuest(leaseId: string, propertyId: number) {
  const postGuest = async (guest: GuestRequest) => {
    return await axios.post(
      `${BASE_API_URL}/resident/guests/property/${propertyId}/lease/${leaseId}`,
      guest
    );
  };

  return useMutation(postGuest);
}

export default function AddGuestPage() {
  const params = useParams<{ leaseId: string }>();
  const { redirect = false } =
    (useLocation().state as {
      redirect: false;
    }) || {};

  const { user } = useLivlyUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isLoading, error } = usePostGuest(
    params.leaseId!,
    user.propertyId
  );
  const { data: services } = useQuery({
    ...getDelegateServiceQuery(user.propertyId, params.leaseId!),
  });

  const { resetGuest, updateGuest, guest } = useLivlyGuest();

  useEffect(() => {
    if (services && guest.serviceTypes == null) {
      const serviceTypes = services.map((service) => ({
        ...service,
        enabled: false,
      }));

      updateGuest({ ...guest, serviceTypes });
    }
  }, [services, updateGuest, guest]);

  const onSubmit = (guest: GuestRequest) => {
    mutate(guest, {
      onSuccess: () => {
        navigate("../guests");
        queryClient.invalidateQueries(["lease-guests"]);
        resetGuest();
      },
    });
  };

  return (
    <Layout back={{ to: "..", label: "My Guests" }} title="Add Guest">
      <div className="max-w-3xl mx-auto">
        <GuestForm
          isLoading={isLoading}
          error={error}
          onSubmit={onSubmit}
          redirect={redirect}
        />
      </div>
    </Layout>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useMemo, useState } from "react";
import { useParams, Link, useOutletContext } from "react-router-dom";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/Dialog";
import Layout from "../../components/Layout";
import { BASE_API_URL } from "../../utils/constants";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BuildingServiceType, ServiceTypeEnum } from "../../types/Building";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";
import useLivlyGuest, {
  getDelegateServiceQuery,
} from "@/context/GuestProvider";
import { motion } from "framer-motion";

export type GuestResponse = {
  guestId: number;
  companyName: string;
  avatarUri: string | null;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  note: string;
  isBanned: boolean;
  banNote: string;
  type: {
    id: GuestCategory;
    category: number;
    name: string;
    isDeleted: boolean;
  };
  serviceTypes: [
    {
      serviceType: number;
      name: "string";
      enabled: boolean;
      metaData: "string";
      permissionAccessLevel?: string;
    }
  ];
  dateCreated?: Date;
  avatarBase64Image: string | null;
  appPermission?: boolean;
  leaseLivlyUserId: number;
  hasKeyAccess?: boolean;
  inviteConfirmed?: boolean;
  hasJoined?: boolean;
  hadAppPermissionGranted?: boolean;
  isDeleted: boolean;
  accessControl: {
    allowUnitDoors: boolean;
    schedule: {
      days: {
        startTime: number;
        endTime: number;
        day: string;
        disabled: boolean;
      }[];
    };
  } | null;
};

export enum GuestType {
  Business = 1,
  Personal = 2,
}

export enum GuestCategory {
  "None" = 0,
  "PersonalGuest" = 1,
  "DogWalker" = 2,
  "Cleaner" = 3,
  "Delivery" = 4,
  "OtherBusiness" = 5,
}

type GuestDataServiceMeta = {
  guestKeyEnabled: boolean;
  guestLimit: number | null;
  livlyGuestPassEnabled: boolean;
};

export async function getGuests(leaseId: string, propertyId: number) {
  const result = await axios.get<BaseLivlyApiResponse<GuestResponse[]>>(
    `${BASE_API_URL}/resident/guests/property/${propertyId}/lease/${leaseId}?includeDeactivated=true`
  );

  return result.data.Data;
}

function useGetGuestLimit() {
  const { user } = useLivlyUser();
  const { building } = user;

  const { serviceTypes = [] } = building || {};
  const guestData: BuildingServiceType | undefined = serviceTypes.find(
    (service: BuildingServiceType) =>
      service.serviceType === ServiceTypeEnum.MyGuests
  );

  const metaData = JSON.parse(
    String(guestData?.metaData)
  ) as GuestDataServiceMeta;

  return metaData.guestLimit;
}

function useDeactivateGuest() {
  const deleteGuest = async (guestId: number) => {
    const result = await axios.delete(
      `${BASE_API_URL}/livly/guests/user/me/${guestId}`
    );

    return result.data.Data;
  };

  return useMutation(deleteGuest);
}

function useActivateGuest(propertyId: number, leaseId: string) {
  const activateGuest = async (guestId: number) => {
    const result = await axios.patch(
      `${BASE_API_URL}/resident/guests/property/${propertyId}/lease/${leaseId}/guests/${guestId}/activate`
    );

    return result.data.Data;
  };

  return useMutation(activateGuest);
}

const myGuestsQuery = (leaseId: string, propertyId: number) => ({
  queryKey: ["lease-guests", leaseId],
  queryFn: async () => getGuests(leaseId, propertyId),
});

export default function MyGuestsPage() {
  const { user } = useLivlyUser();
  const { resetGuest } = useLivlyGuest();
  const { isGuestServiceDisabled, showNoAccessDialog } = useOutletContext<{
    isGuestServiceDisabled: boolean;
    showNoAccessDialog: Function;
  }>();
  const [status, setStatus] = useState<"active" | "inactive">("active");

  const params = useParams<{ leaseId: string }>();

  const { data, isLoading } = useQuery({
    ...myGuestsQuery(params.leaseId!, user.propertyId),
  });
  const { isLoading: serviceLoading } = useQuery({
    ...getDelegateServiceQuery(user.propertyId, params.leaseId!),
  });

  const guestLimit = useGetGuestLimit();
  const filteredGuests = useMemo(() => {
    if (status === "active") {
      return data?.filter((guest) => !guest.isDeleted);
    }
    return data?.filter((guest) => guest.isDeleted);
  }, [data, status]);

  const activeGuests = (data ?? []).filter(
    (guest) => !guest.isDeleted && !guest.isBanned
  );

  let isAddGuestDisabled = false;
  if (guestLimit !== null) {
    isAddGuestDisabled = activeGuests.length >= guestLimit;
  }

  return (
    <Layout title="Guests">
      {isLoading || serviceLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <div className="flex items-center gap-4">
            {["active", "inactive"].map((buttonStatus) => (
              <button
                key={buttonStatus}
                className={`flex-1 flex justify-center md:flex-none relative rounded-lg px-3 py-2 text-gray-700 text-sm font-medium transition-colors delay-150 capitalize`}
                onClick={() => setStatus(buttonStatus as "active" | "inactive")}
              >
                {status === buttonStatus && (
                  <motion.span
                    className="absolute inset-0 rounded-lg bg-red-100 "
                    layoutId="hoverBackground"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 0.15 } }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.15, delay: 0.2 },
                    }}
                  />
                )}
                <div className="flex gap-1">
                  <span
                    className={`relative z-[1] ${
                      status === buttonStatus && "text-red-400"
                    }`}
                  >
                    {buttonStatus}
                  </span>
                </div>
              </button>
            ))}
          </div>
          {data?.length === 0 ? (
            <div className="flex flex-col items-center">
              <p className="text-xl">No guests added yet</p>
              <p className="mt-2 text-sm font-light">
                Any guests added to the lease will appear here
              </p>
              <Link
                to="add"
                onClick={(e) => {
                  if (isGuestServiceDisabled) {
                    e.preventDefault();
                    e.stopPropagation();
                    showNoAccessDialog();
                  }
                }}
              >
                <Button
                  size="small"
                  color="primary"
                  className="mt-4"
                  onClick={() => resetGuest()}
                >
                  Add a guest
                </Button>
              </Link>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between mt-4">
                {guestLimit != null && guestLimit > 0 && (
                  <p>
                    <span className="text-red-400 ">
                      {activeGuests?.length}
                    </span>{" "}
                    out of {guestLimit} guests
                  </p>
                )}
                <Link
                  to="add"
                  onClick={(e) => {
                    if (isGuestServiceDisabled) {
                      e.preventDefault();
                      e.stopPropagation();
                      showNoAccessDialog();
                    }
                  }}
                >
                  <Button
                    size="small"
                    onClick={() => resetGuest()}
                    disabled={isAddGuestDisabled}
                  >
                    Add Guest
                  </Button>
                </Link>
              </div>
              <ul className="mt-4 divide-y divide-gray-200">
                {filteredGuests?.map((guest) => (
                  <GuestItem
                    key={guest.guestId}
                    guest={guest}
                    leaseId={params.leaseId!}
                    userId={user.userId}
                    propertyId={user.propertyId}
                    guestLimit={guestLimit}
                    activeGuestCount={activeGuests.length}
                  />
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </Layout>
  );
}

function GuestItem({
  guestLimit,
  activeGuestCount,
  guest,
  leaseId,
  propertyId,
}: {
  guest: GuestResponse;
  leaseId: string;
  userId: number;
  propertyId: number;
  guestLimit: number | null;
  activeGuestCount: number;
}) {
  const { isGuestServiceDisabled, showNoAccessDialog } = useOutletContext<{
    isGuestServiceDisabled: boolean;
    showNoAccessDialog: Function;
  }>();
  const [isOpen, setIsOpen] = useState(false);
  const [isReactivateOpen, setIsReactivateOpen] = useState(false);
  const [isGuestLimitWarningOpen, setIsGuestLimitWarningOpen] = useState(false);
  const client = useQueryClient();
  const {
    mutate: deactivateGuest,
    isLoading,
    isSuccess,
  } = useDeactivateGuest();
  const { mutate: activateGuest, isLoading: isActivatingGuest } =
    useActivateGuest(propertyId, leaseId);
  const { resetGuest } = useLivlyGuest();

  const onActivateGuest = () => {
    if (guestLimit !== null && activeGuestCount >= guestLimit) {
      setIsGuestLimitWarningOpen(true);
    } else {
      setIsReactivateOpen(true);
    }
  };

  const onDeactivate = () => {
    deactivateGuest(guest.guestId, {
      onSuccess: () => {
        setIsOpen(false);
        client.invalidateQueries(["lease-guests", leaseId]);
      },
    });
  };

  if (isSuccess) {
    return null;
  }

  return (
    <li className="flex items-center py-4" data-test-id="guest-list-item">
      {guest.isBanned ? (
        <div className="relative flex items-center justify-center w-16 h-16 bg-gray-200 rounded-full">
          <span className="">
            <FontAwesomeIcon
              icon={
                guest.type.category === GuestType.Business ? "building" : "user"
              }
            />
            <FontAwesomeIcon
              icon="ban"
              className="text-red-400 absolute right-[2px] bottom-[2px] text-lg"
            />
          </span>
        </div>
      ) : guest.avatarUri ? (
        <img
          src={guest.avatarUri}
          className="object-cover w-16 h-16 rounded-full"
          alt={guest.firstName}
        />
      ) : (
        <div className="flex items-center justify-center w-16 h-16 bg-gray-200 rounded-full">
          <FontAwesomeIcon
            icon={
              guest.type.category === GuestType.Business ? "building" : "user"
            }
          />
        </div>
      )}
      <div className="flex-1 ml-4">
        <div className="flex items-center">
          <p className="text-lg font-semibold mr-2">
            {guest.type.category === GuestType.Business
              ? guest.firstName || guest.lastName
                ? `${guest.firstName} ${guest.lastName}`
                : guest.companyName
              : `${guest.firstName} ${guest.lastName}`}{" "}
          </p>
          {guest?.appPermission && !guest?.hasJoined ? (
            <div className="p-1 bg-gray-100 border border-gray-400 text-gray-500 text-xs h-5 rounded-sm flex justify-center items-center">
              <p>Invited</p>
            </div>
          ) : guest?.hasJoined ? (
            <div className="p-1 bg-green-100 border border-green-400 text-green-500 text-xs h-5 rounded-sm flex justify-center items-center">
              <p>Joined</p>
            </div>
          ) : null}
          {guest.hasKeyAccess ? (
            <div className="text-red-400 mx-2">
              <FontAwesomeIcon icon={["fas", "key"]} />
            </div>
          ) : null}
        </div>
        <p className="text-gray-500">{guest.type.name}</p>
      </div>
      {guest.isBanned ? (
        <p className="font-semibold text-red-400">Banned</p>
      ) : (
        <div className="flex gap-2">
          {!guest.isDeleted && (
            <Link
              to={guest.guestId.toString()}
              onClick={(e) => {
                resetGuest();
                if (isGuestServiceDisabled) {
                  e.preventDefault();
                  e.stopPropagation();
                  showNoAccessDialog();
                }
              }}
              className="text-sm underline text-[#262b2b]"
            >
              Edit
            </Link>
          )}
          {guest.isDeleted && (
            <button
              className="text-sm underline text-[#262b2b]"
              disabled={isActivatingGuest}
              onClick={onActivateGuest}
            >
              {isActivatingGuest ? "Activating" : "Activate"}
            </button>
          )}
          {!guest.isDeleted && (
            <button
              className="text-sm underline text-[#262b2b]"
              onClick={() => {
                if (isGuestServiceDisabled) {
                  showNoAccessDialog();
                } else {
                  setIsOpen(true);
                }
              }}
            >
              Deactivate
            </button>
          )}
        </div>
      )}
      <ConfirmationModal
        variant="warning"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Deactivate Guest"
        body="Are you sure you want to deactivate this guest?"
        buttonLabels={{ confirm: isLoading ? "Deactivating" : "Deactivate" }}
        onConfirm={onDeactivate}
        isLoading={isLoading}
      />
      <ConfirmationModal
        variant="success"
        open={isReactivateOpen}
        onClose={() => setIsReactivateOpen(false)}
        title="Reactivate Guest"
        body="Area you sure you want to reactivate this guest?"
        buttonLabels={{ confirm: isLoading ? "Reactivating" : "Reactivate" }}
        onConfirm={() => {
          activateGuest(guest.guestId, {
            onSuccess: () => {
              setIsReactivateOpen(false);
              client.invalidateQueries(["lease-guests", leaseId]);
            },
          });
        }}
        isLoading={isLoading}
      />
      <ConfirmationModal
        variant="warning"
        open={isGuestLimitWarningOpen}
        onClose={() => setIsGuestLimitWarningOpen(false)}
        title="Reactivate Guest"
        body={`You are only allowed to have ${guestLimit} active guests at one time.  Please deactivate a different guest before continuing.`}
        buttonLabels={{ confirm: "Ok" }}
        onConfirm={() => {
          setIsGuestLimitWarningOpen(false);
        }}
      />
    </li>
  );
}

import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import axios from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { z } from "zod";
import { ServiceTypeEnum } from "@/types/Building";
import moment from "moment-timezone";
import { DayOfWeek } from "@/types/User";
import useLivlyUser from "./UserProvider";

export type DelegateServiceTypes = {
  enabled: boolean;
  metaData: string;
  name: string;
  serviceType: ServiceTypeEnum;
};

export async function useGetDelegateService(
  propertyId: number,
  leaseId: string
) {
  const result = await axios.get<BaseLivlyApiResponse<DelegateServiceTypes[]>>(
    `${BASE_API_URL}/resident/guests/property/${propertyId}/lease/${leaseId}/serviceTypes`
  );

  return result.data.Data;
}

export const getDelegateServiceQuery = (
  propertyId: number,
  leaseId: string
) => ({
  queryKey: ["delegate-services", propertyId, leaseId],
  queryFn: async () => useGetDelegateService(propertyId, leaseId),
});

export interface GuestWaiverMetaData {
  waiverRequired: boolean;
  waiverText: string | null;
}

enum GuestCategory {
  "None" = 0,
  "PersonalGuest" = 1,
  "DogWalker" = 2,
  "Cleaner" = 3,
  "Delivery" = 4,
  "OtherBusiness" = 5,
}

export const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

export const phonePlaceholder = "(___) ___-____";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const AccessControlSchema = z.object({
  allowUnitDoors: z.boolean().optional(),
  schedule: z
    .object({
      days: z.array(
        z.object({
          startTime: z.number(),
          endTime: z.number(),
          day: z.string(),
          disabled: z.boolean(),
        })
      ),
    })
    .nullable(),
});

export const GuestSchema = z
  .object({
    companyName: z.string().optional(),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string().optional(),
    email: z.string().optional(),
    note: z.string().optional(),
    staffNotes: z.string().nullable(),
    guestType: z.string(),
    avatarUri: z.string().nullable(),
    avatarBase64Image: z.string().nullable(),
    guestId: z.number().optional(),
    hadAppPermissionGranted: z.boolean().optional(),
    type: z
      .object({
        id: z.number(),
        category: z.number(),
        name: z.string(),
        isDeleted: z.boolean().nullable(),
      })
      .optional(),
    accessControl: AccessControlSchema,
    serviceTypes: z
      .array(
        z
          .object({
            serviceType: z.number(),
            name: z.string(),
            enabled: z.boolean(),
            metaData: z.string(),
          })
          .optional()
      )
      .optional(),
    waiverAccepted: z.boolean(),
    appPermission: z.boolean(),
  })
  .superRefine((val, ctx) => {
    if (
      val.appPermission &&
      !val.email?.trim().length &&
      !val.phone?.trim().length
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["phone"],
        message: "Phone number is required",
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["email"],
        message: "Email address is required",
      });
    }
    if (!val.email?.trim().length && !val.phone?.trim().length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["phone"],
        message: "Phone or email is required",
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["email"],
        message: "Phone or email is required",
      });
    }

    if (
      val?.phone !== phonePlaceholder &&
      val.phone?.trim().length &&
      val.phone?.trim().length > 0 &&
      !phoneRegex.test(val.phone?.trim())
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["phone"],
        message: "Enter valid phone number",
      });
    }

    if (
      val.email?.trim().length &&
      val.email?.trim().length > 0 &&
      !emailRegex.test(val.email?.trim())
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["email"],
        message: "Enter valid email",
      });
    }

    if (val.guestType !== GuestCategory.PersonalGuest.toString()) {
      if (!val.companyName?.trim().length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["companyName"],
          message: "Company name is required",
        });
      }
    } else {
      if (!val.firstName?.trim().length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["firstName"],
          message: "First name is required",
        });
      }
      if (!val.lastName?.trim().length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["lastName"],
          message: "Last name is required",
        });
      }
    }
  });

export type GuestRequest = z.infer<typeof GuestSchema>;
export type AccessControl = z.infer<typeof AccessControlSchema>;

const today = new Date();
export const midnight = moment(today).startOf("day");
export const startTime = moment(today).set({ hour: 7, minute: 0, second: 0 });
export const endTime = moment(today).set({ hour: 22, minute: 0, second: 0 });

export const getInitialDays = () => {
  const today = new Date();
  const midnight = moment(today).startOf("day");
  const startTime = moment(today).set({ hour: 7, minute: 0, second: 0 });
  const endTime = moment(today).set({ hour: 22, minute: 0, second: 0 });

  const time = moment(startTime).diff(midnight, "minutes");
  const length = endTime.diff(startTime, "minutes");

  return [
    {
      time,
      length,
      day: DayOfWeek.Sunday,
      disabled: false,
    },
    {
      time,
      length,
      day: DayOfWeek.Monday,
      disabled: false,
    },
    {
      time,
      length,
      day: DayOfWeek.Tuesday,
      disabled: false,
    },
    {
      time,
      length,
      day: DayOfWeek.Wednesday,
      disabled: false,
    },
    {
      time,
      length,
      day: DayOfWeek.Thursday,
      disabled: false,
    },
    {
      time,
      length,
      day: DayOfWeek.Friday,
      disabled: false,
    },
    {
      time,
      length,
      day: DayOfWeek.Saturday,
      disabled: false,
    },
  ];
};

export const initialAccessControlSchedule = {
  days: getInitialDays(),
};

interface GuestContextShape {
  guest: GuestRequest;
  updateGuest: (user: Partial<GuestRequest>) => void;
  resetGuest: () => void;
}

const initialState: GuestContextShape = {
  guest: {} as GuestRequest,
  updateGuest: () => {},
  resetGuest: () => {},
};

const GuestContext = createContext<GuestContextShape>(
  initialState as GuestContextShape
);

const getInitialGuestValues = (scheduleSettings: GuestKeySchedule[]) => {
  // Define the initial values
  const initialValues: GuestRequest = {
    companyName: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    staffNotes: null,
    guestType: GuestCategory.PersonalGuest.toString(),
    waiverAccepted: true,
    avatarUri: null,
    avatarBase64Image: null,
    appPermission: false,
    serviceTypes: undefined,
    accessControl: {
      allowUnitDoors: false,
      schedule: {
        days: scheduleSettings.map((d) => ({
          day: getFriendlyDayOfWeek(d.day),
          startTime: d.time,
          endTime: d.time + d.length,
          disabled: d.disabled,
        })),
      },
    },
  };

  return initialValues;
};

// Create a provider component that will wrap your application
function GuestRequestProvider() {
  const scheduleSettings = useGetLocksScheduleSettings();
  // Use state to manage the guest request data
  const [guest, setGuestRequest] = useState<GuestRequest>(
    getInitialGuestValues(scheduleSettings)
  );

  // Function to update the guest request data
  const updateGuest = useCallback((newData: Partial<GuestRequest>) => {
    setGuestRequest((prevData) => ({ ...prevData, ...newData }));
  }, []);

  const resetGuest = useCallback(() => {
    setGuestRequest(getInitialGuestValues(scheduleSettings));
  }, []);

  // Provide the context value to the components in the tree

  const value = useMemo(
    () => ({
      guest: guest!,
      updateGuest,
      resetGuest,
    }),
    [guest, updateGuest, resetGuest]
  );

  return (
    <GuestContext.Provider value={value}>
      <ScrollRestoration />
      <Outlet />
    </GuestContext.Provider>
  );
}

export { GuestRequestProvider };

export default function useLivlyGuest() {
  const context = useContext(GuestContext);
  if (!context) {
    throw new Error(`useGuestUser must be used within a UserProvider`);
  }

  return context;
}

interface GuestKeySchedule {
  day: number;
  time: number;
  length: number;
  disabled: boolean;
}

interface LivlyLocksMeta {
  restrictGuestKeyScheduleAccess: boolean;
  guestKeySchedule: {
    dayOfWeek: number;
    time: number;
    length: number;
    disabled: boolean;
  }[];
}

export function useGetLocksScheduleSettings(): GuestKeySchedule[] {
  const defaultSchedule = getInitialDays();
  const {
    user: { building },
  } = useLivlyUser();
  const locksService = building.serviceTypes?.find(
    (st) => st.serviceType === ServiceTypeEnum.LivlyLocks
  );

  if (locksService?.metaData) {
    const metaData = JSON.parse(locksService.metaData) as LivlyLocksMeta;
    const schedule = metaData.guestKeySchedule;

    if (metaData.restrictGuestKeyScheduleAccess) {
      return schedule.map((day) => ({
        day: day.dayOfWeek,
        time: day.time,
        length: day.length,
        disabled: false,
      }));
    }
  }

  return defaultSchedule;
}

export function getFriendlyDayOfWeek(dayOfWeek: DayOfWeek | string) {
  if (typeof dayOfWeek === "string") {
    return dayOfWeek;
  }

  switch (dayOfWeek) {
    case DayOfWeek.Monday:
      return "Monday";
    case DayOfWeek.Tuesday:
      return "Tuesday";
    case DayOfWeek.Wednesday:
      return "Wednesday";
    case DayOfWeek.Thursday:
      return "Thursday";
    case DayOfWeek.Friday:
      return "Friday";
    case DayOfWeek.Saturday:
      return "Saturday";
    case DayOfWeek.Sunday:
      return "Sunday";
  }
}

export function convertFriendlyDayOfWeekToEnum(dayOfWeek: string) {
  switch (dayOfWeek) {
    case "Monday":
      return DayOfWeek.Monday;
    case "Tuesday":
      return DayOfWeek.Tuesday;
    case "Wednesday":
      return DayOfWeek.Wednesday;
    case "Thursday":
      return DayOfWeek.Thursday;
    case "Friday":
      return DayOfWeek.Friday;
    case "Saturday":
      return DayOfWeek.Saturday;
    case "Sunday":
      return DayOfWeek.Sunday;
  }
}

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { variantProps, VariantPropsOf } from "classname-variants/react";
import { Spinner } from "./Spinner";
import classNames from "classnames";

type Variants = "success" | "warning" | "info";

type DialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  body: string;
  buttonLabels?: {
    confirm?: string;
    cancel?: string | null;
  };
  onConfirm: () => Promise<void> | void;
  isLoading?: boolean;
  variant?: Variants;
};

function ConfirmationModal({
  title,
  body,
  open,
  onClose,
  buttonLabels = { confirm: "Yes", cancel: "Cancel" },
  onConfirm,
  isLoading,
  variant = "info",
}: DialogProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-3 pr-2 sm:block">
                  <button
                    type="button"
                    className="w-6 h-6 text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon icon="times" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <DialogIcon variant={variant} />
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">{body}</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={classNames(
                      `inline-flex w-full items-center gap-2 justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm `,
                      {
                        "bg-red-600  hover:bg-red-700 focus:ring-red-500":
                          variant === "warning",
                        "bg-blue-600  hover:bg-blue-700 focus:ring-blue-500":
                          variant !== "warning",
                      }
                    )}
                    onClick={onConfirm}
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner />}
                    {buttonLabels.confirm}
                  </button>
                  {buttonLabels.cancel !== null && (
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={onClose}
                    >
                      {buttonLabels.cancel || "Cancel"}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function DialogIcon({ variant }: { variant: Variants }) {
  if (variant === "warning") {
    return (
      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
        <FontAwesomeIcon
          icon="exclamation-triangle"
          className="text-red-600"
          aria-hidden="true"
        />
      </div>
    );
  } else if (variant === "success") {
    return (
      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-green-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
        <FontAwesomeIcon
          icon="check"
          size="lg"
          className="text-green-500"
          aria-hidden="true"
        />
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-blue-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
        <FontAwesomeIcon
          icon={["fas", "exclamation-square"]}
          size="lg"
          className="text-blue-600"
          aria-hidden="true"
        />
      </div>
    );
  }
}
const dialogProps = variantProps({
  base: "relative transform overflow-hidden overflow-y-auto rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 w-full px-4 pt-5 pb-4",
  variants: {
    size: {
      sm: "max-w-sm",
      md: "max-w-md",
      lg: "max-w-lg",
      xl: "max-w-xl",
    },
    padding: {
      false: "px-0 pt-0 pb-0",
    },
  },
  defaultVariants: {
    size: "sm",
    padding: true,
  },
});

type Props = {
  open: boolean;
  onClose: (open: boolean) => void;
  children: React.ReactNode;
  title?: string;
  hideClose?: boolean;
} & VariantPropsOf<typeof dialogProps>;

function Modal({
  open,
  onClose,
  title,
  children,
  hideClose = false,
  ...rest
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel {...dialogProps(rest)}>
                {!hideClose && (
                  <div className="absolute top-0 right-0 hidden pt-3 pr-2 sm:block">
                    <button
                      type="button"
                      className="w-6 h-6 text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => onClose(false)}
                    >
                      <span className="sr-only">Close</span>
                      <FontAwesomeIcon icon="times" aria-hidden="true" />
                    </button>
                  </div>
                )}
                {title && (
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {title}
                      </Dialog.Title>
                    </div>
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { ConfirmationModal, Modal };

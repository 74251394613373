import { Task, User } from "@/types/User";

export interface Feedback {
  residentFeedbackId: number;
  leaseLivlyUserId: number;
  feedbackType: FeedbackType | undefined;
  entityId: number;
  metaData: Record<string, string>;
  createdTimestamp: string | Date;
  availableTimestamp: string | Date;
  availableUntilTimestamp: string | Date;
  rating: number;
  comment: string;
  feedbackItems: number[];
  completedTimestamp: string | Date;
  options: {
    positive: FeedbackOption[];
    negative: FeedbackOption[];
  };
  reviewLocations: ReviewLocation[];
}

export type FeedbackType =
  | "MaintenanceTicket"
  | "MaintenanceAIChat"
  | "Property"
  | "AmenityBooking"
  | "Event"
  | "MoveIn";

export interface ReviewLocation {
  isDefault: boolean;
  name: string;
  type: number;
  typeImageUrl: string;
  url: string;
}

export interface FeedbackOption {
  residentFeedbackItemOptionTypeId: number;
  description: string;
  isPositive: boolean;
  isNegative: boolean;
  feedbackType: number;
}

export interface FeedbackRequest {
  feedbackId: number;
  rating: number | null;
  comment: string;
  feedbackItems: number[];
}

export function getModalTitle(feedback: Feedback | undefined) {
  let title = "";
  let subtitle = "";

  const feedbackType = feedback?.metaData?.feedbackType as FeedbackType;

  switch (feedbackType) {
    case "MaintenanceAIChat":
      title = "Thank you for using Livly’s Assistant";
      subtitle = "Rate your experience below";
      break;
    case "AmenityBooking":
      const amenityName = feedback?.metaData?.amenityName as string;
      title = `How was your ${amenityName} booking?`;
      subtitle = "Rate your experience below";
      break;
    default:
      title = `We'd love your feedback!`;
      subtitle = `Tell us what you'd like to improve in your living experience.`;
      break;
  }

  return { title, subtitle };
}

export function defineFeedbackRatingFormOptions(
  task: Task,
  user: User,
  hideCategory: boolean = false
) {
  let title = "";
  let subtitle = "Rate your feelings below";

  const feedbackType = task.metaData?.feedbackType as FeedbackType;

  switch (feedbackType) {
    case "MaintenanceAIChat":
      title = "Thank you for using Livly’s Assistant";
      break;
    case "MaintenanceTicket":
      const categoryName = (task.metaData?.categoryName as string) || "";
      title = `Your ${
        !hideCategory ? categoryName : ""
      } maintenance ticket was recently closed.`;
      subtitle = "Rate your experience below";
      break;
    case "AmenityBooking":
      const amenityName = task.metaData?.amenityName as string;
      title = `How was your ${amenityName} booking?`;
      subtitle = "Rate your experience below";
      break;
    case "Event":
      const eventName = task.metaData?.eventName as string;
      title = `How was ${eventName}?`;
      subtitle = "Rate your experience below";
      break;
    case "Property":
      title = `How is your experience living in ${user.buildingName}?`;
      break;
    case "MoveIn":
      title = "How was your move-in?";
      subtitle = "Rate your experience below";
      break;
    default:
      title = "We'd love your feedback!";
      break;
  }

  return { title, subtitle };
}

export function defineFeedbackFormOptions(
  feedback: Feedback,
  feedbackRequestModel: FeedbackRequest | null,
  buildingName: string,
  hideCategory: boolean = false
) {
  let title = "";
  let subtitle = "Rate your experience below";

  switch (feedback.feedbackType) {
    case "MaintenanceAIChat":
      title = "Thank you for using Livly’s Assistant";
      break;
    case "MaintenanceTicket":
      const categoryName = (feedback.metaData?.categoryName as string) || "";
      title = `Your ${
        !hideCategory ? categoryName : ""
      } maintenance ticket was recently closed.`;
      break;
    case "AmenityBooking":
      const amenityName = feedback.metaData?.amenityName as string;
      title = `How was your ${amenityName} booking?`;
      break;
    case "Event":
      const eventName = feedback.metaData?.eventName as string;
      title = `How was ${eventName}?`;
      break;
    case "Property":
      title = "We'd love your feedback!";
      subtitle =
        "Tell us what you'd like to improve in your living experience.";
      break;
    case "MoveIn":
      title = "How was your move-in?";
      break;
    default:
      title = "We'd love your feedback!";
      break;
  }

  const isNegativeRating = [0, 1].includes(
    Number(feedbackRequestModel?.rating)
  );
  const feedbackOptions = isNegativeRating
    ? feedback?.options?.negative
    : feedback?.options?.positive;

  const ratingTitle = isNegativeRating
    ? "How can your experience be improved?"
    : " Great! Tell us what you liked.";

  return { title, ratingTitle, subtitle, feedbackOptions };
}

export function getTitleForModal(
  feedbackType: FeedbackType,
  buildingName: string
) {
  switch (feedbackType) {
    case "AmenityBooking":
      return `How’s your experience with this amenity booking?`;
    case "Event":
      return `How’s your experience with this event?`;
    case "MaintenanceTicket":
      return `How’s your experience with this maintenance service?`;
    case "Property":
      return `How’s your experience with ${buildingName}?`;
    default:
      return `How has your experience been?`;
  }
}

import ReactDOM from "react-dom/client";

import "./index.css";
import "./init";
import "./components/Icons";
import App from "./App";
import "./i18n";
import {
  MOBILE_KEY,
  ACCESS_TOKEN_KEY,
  EXPIRES_IN_KEY,
  AUTHORIZATION_HEADER_KEY,
  RETURN_TO_URL,
} from "./utils/localStorage";
import axios from "axios";
import { BaseLivlyApiResponse } from "./types/Base";
import { User } from "./types/User";
import { BASE_API_URL } from "./utils/constants";
import * as jose from "jose";
import { WEBVIEW_PATHS } from "./utils/webviews";

async function bootstrapApp() {
  const params = getQueryParams();
  const { mobile, accessToken, expiresIn, path: webviewPath, leaseId } = params;
  let userId = params.userId;

  // if it is a webview, construct the route and redirect
  if (mobile && accessToken) {
    await setAuthValuesInLocalStorage(expiresIn!, accessToken);

    if (!userId) {
      const user = await getUser(leaseId, accessToken);
      userId = user.userId.toString();
    }

    const baseLeasePath = `lease/:leaseId`;
    let path = "";

    // this is to account for info desk, its an antipattern but nothing to do about it at this point
    // /$buildingId/page/$pageId
    if (webviewPath.includes("/page/")) {
      path = webviewPath.replace(":leaseId", leaseId);
    }

    if (webviewPath.includes(WEBVIEW_PATHS.INSURANCE_MARKETPLACE)) {
      path = `checklist/:userId/insurance-marketplace`;
    }

    switch (webviewPath) {
      case WEBVIEW_PATHS.CHECKLIST:
        path = `checklist/:userId`;
        break;
      case WEBVIEW_PATHS.PROFILE:
        path = `checklist/:userId/profile`;
        break;
      case WEBVIEW_PATHS.INSURANCE_QUOTE:
        path = `checklist/:userId/insurance`;
        break;
      case WEBVIEW_PATHS.UTILITIES:
        path = `checklist/:userId/utilities/:userId`;
        break;
      case WEBVIEW_PATHS.SCHEDULE_MOVE_IN:
        path = `checklist/:userId/schedule-move-in`;
        break;
      case WEBVIEW_PATHS.COMMUNITY_SURVEY:
        path = `checklist/:userId/community-survey`;
        break;
      case WEBVIEW_PATHS.CHECKOUT:
        path = `checklist/:userId/cart`;
        break;
      case WEBVIEW_PATHS.RENT:
        path = `rent/:userId`;
        break;
      case WEBVIEW_PATHS.FITNESS_PAYMENTS:
        path = `fitness/payment-methods`;
        break;
      case WEBVIEW_PATHS.INSPECTION_CHECKLIST:
        path = `inspection-checklist`;
        break;
      case WEBVIEW_PATHS.INSURANCE_PROOF_CURRENT:
        path = `insurance/upload-proof`;
        break;
      case WEBVIEW_PATHS.INSURANCE_QUOTE_CURRENT:
        path = `insurance-quote`;
        break;
      case WEBVIEW_PATHS.AMENIFY:
        path = `amenify`;
        break;
    }

    const pathWithParams = `${baseLeasePath}/${path}`
      .replace(":leaseId", leaseId)
      .replace(":userId", userId)
      .replace(/\/+/g, "/");

    const route = `${window.location.origin}/${pathWithParams}`;
    window.location.replace(route);
  } else {
    const isAccessTokenPresent = !!localStorage.getItem(ACCESS_TOKEN_KEY);
    const pathname = window.location.pathname;
    if (
      !isAccessTokenPresent &&
      pathname &&
      !["/logout", "/callback"].includes(pathname) &&
      //this has to be separate to account for branded login pages
      !pathname.includes("/login")
    ) {
      localStorage.setItem(RETURN_TO_URL, pathname);
    }

    ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
      <App />
    );
  }
}

bootstrapApp();

function getQueryParams() {
  const queryString: string = window.location.search;
  const params = new URLSearchParams(queryString);
  const mobile = params.get(MOBILE_KEY) === "true";
  const accessToken = params.get(ACCESS_TOKEN_KEY);
  const expiresIn = params.get(EXPIRES_IN_KEY);
  const leaseId = params.get("property_unit_lease_id") as string;
  const path = params.get("path") ?? "checklist";

  let userId: string = "";

  if (accessToken) {
    const decodedToken = jose.decodeJwt(accessToken);
    userId = decodedToken["https://api.binway.com/livly_user_id"] as string;
  }

  return { mobile, accessToken, expiresIn, leaseId, path, userId };
}

async function setAuthValuesInLocalStorage(
  expiresIn: string,
  accessToken: string
) {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (expiresIn) {
        localStorage.setItem(EXPIRES_IN_KEY, expiresIn);
      }
      localStorage.setItem(MOBILE_KEY, "true");
      localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

      resolve(true);
    }, 0);
  });
}

async function getUser(leaseId: string, accessToken: string) {
  const result = await axios.get<BaseLivlyApiResponse<User>>(
    `${BASE_API_URL}/livly/users/me?v=202404&leaseId=${leaseId}`,
    { headers: { [AUTHORIZATION_HEADER_KEY]: `Bearer ${accessToken}` } }
  );

  return result.data.Data;
}

import Alert from "@/components/Alert";
import { Spinner } from "@/components/Spinner";
import useLivlyUser from "@/context/UserProvider";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { use } from "i18next";
import { useEffect } from "react";

export default function AmenifyPage() {
  const {
    user: { propertyUnitLeaseId },
  } = useLivlyUser();
  const isFromNativeApp = localStorage.getItem("mobile") === "true";
  const { data, error } = useGetAmenifyUrl(propertyUnitLeaseId);

  //handle the case where the user is coming from the native app open in view otherwize open in new tab

  useEffect(() => {
    if (data?.Url) {
      if (isFromNativeApp) {
        window.location.replace(data.Url);
      } else {
        window.open(data.Url);
      }
    }
  }, [data]);

  /* if (data) {
    return <iframe src={data.Url} className="w-full h-screen" />;
  } */

  if (error) {
    return (
      <div className="m-4 text-center">
        <Alert message="Failed to load Amenify" />
      </div>
    );
  }

  if (data?.Url) {
    return (
      <div className="m-4 text-center">
        <Alert variant="info" message="Amenify opened in a new tab." />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center my-56">
      <Spinner color="livly" size="xl" />
    </div>
  );
}

interface Amenify {
  Url: string;
}

async function getAmnenifyUrl(leaseId: number) {
  const result = await axios.get<BaseLivlyApiResponse<Amenify>>(
    `${BASE_API_URL}/v2/users/leases/${leaseId}/amenifyUrl`
  );

  return result.data.Data;
}

function useGetAmenifyUrl(leaseId: number) {
  return useQuery({
    queryKey: ["amenify", leaseId],
    queryFn: () => getAmnenifyUrl(leaseId),
  });
}

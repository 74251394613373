import Alert from "@/components/Alert";
import { Button } from "@/components/Button";
import { Modal } from "@/components/Dialog";
import LivlySwitch from "@/components/LivlySwitch";
import useLivlyGuest, {
  endTime,
  startTime,
  midnight,
  useGetLocksScheduleSettings,
  getFriendlyDayOfWeek,
  GuestRequest,
  AccessControl,
  convertFriendlyDayOfWeekToEnum,
} from "@/context/GuestProvider";
import useLivlyUser from "@/context/UserProvider";
import { DayOfWeek } from "@/types/User";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type DaySelected = {
  startTime: number;
  endTime: number;
  day: string;
  disabled: boolean;
};

export const AccessScheduleModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const {
    user: {
      building: { timezone },
    },
  } = useLivlyUser();
  const [tempAccessControl, setTempAccessControl] =
    useState<AccessControl | null>(null);
  const scheduleFromSettings = useGetLocksScheduleSettings();
  const { guest, updateGuest } = useLivlyGuest();
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState<DayOfWeek | null>(
    null
  );

  useEffect(() => {
    if (open) {
      setTempAccessControl({ ...guest.accessControl });
    }
  }, [open]);

  const handleDateTimeChange = (time: Date, type: "startTime" | "endTime") => {
    if (selectedDayOfWeek !== null) {
      const newTime = moment(time).diff(midnight, "minutes");

      const accessControlScheduleDays =
        tempAccessControl?.schedule?.days.map((d) => {
          if (d.day === getFriendlyDayOfWeek(selectedDayOfWeek!)) {
            return { ...d, [type]: newTime };
          }

          return d;
        }) ?? [];

      setTempAccessControl({
        ...tempAccessControl,
        schedule: { days: accessControlScheduleDays },
      });
    }
  };

  const handleReset = () => {
    const days = scheduleFromSettings.map((day) => ({
      day: getFriendlyDayOfWeek(day.day),
      startTime: day.time,
      endTime: day.time + day.length,
      disabled: day.disabled,
    }));

    setTempAccessControl({
      schedule: { ...tempAccessControl?.schedule, days },
    });

    console.log("days ", days);
  };

  const handleSetDisabled = (disabled: boolean) => {
    const days =
      guest?.accessControl?.schedule?.days?.map((day) => {
        if (day.day === getFriendlyDayOfWeek(selectedDayOfWeek!)) {
          return { ...day, disabled: !disabled };
        }

        return day;
      }) ?? [];

    setTempAccessControl({
      schedule: { ...tempAccessControl?.schedule, days },
    });
  };

  const onDone = () => {
    if (tempAccessControl) {
      updateGuest({ ...guest, accessControl: tempAccessControl });
    }

    onClose();
  };

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const offset = getTimezoneDifference(browserTimezone, timezone);
  const currentSettingForSelectedDay = scheduleFromSettings.find(
    (d) => selectedDayOfWeek !== null && d.day === selectedDayOfWeek
  );
  const selectedDay =
    selectedDayOfWeek !== null &&
    tempAccessControl?.schedule?.days.find(
      (d) => d.day === getFriendlyDayOfWeek(selectedDayOfWeek)
    );

  return (
    <Modal open={open} onClose={onClose} size="lg">
      <div className="relative flex items-center justify-center">
        {selectedDay ? (
          <button
            className="absolute left-0 p-4"
            onClick={() => setSelectedDayOfWeek(null)}
          >
            <FontAwesomeIcon
              icon={["fas", "chevron-left"]}
              className="text-black"
            />
          </button>
        ) : null}
        <div className="font-medium">Schedule Access</div>
      </div>

      {selectedDay ? (
        <>
          <div className="mt-4">
            {currentSettingForSelectedDay && (
              <p className="text-gray-500 mt-8">
                Pick a time window between{" "}
                {moment()
                  .startOf("day")
                  .add(currentSettingForSelectedDay.time, "minutes")
                  .format("h:mm A")}{" "}
                and{" "}
                {moment()
                  .startOf("day")
                  .add(
                    currentSettingForSelectedDay.time +
                      currentSettingForSelectedDay.length,
                    "minutes"
                  )
                  .format("h:mm A")}
                .
              </p>
            )}
            <div className="flex items-center justify-between py-6">
              <p>{getFriendlyDayOfWeek(selectedDay.day)}</p>
              <LivlySwitch
                checked={!selectedDay.disabled}
                onChange={(checked: boolean) => handleSetDisabled(checked)}
              />
            </div>
            {!selectedDay.disabled && (
              <div className="">
                <div className={`border-y py-6 flex items-center`}>
                  <p className="flex-1">From</p>
                  <DatePicker
                    selected={moment()
                      .startOf("day")
                      .add(selectedDay.startTime, "minutes")
                      .add(-offset, "hour")
                      .toDate()}
                    onChange={(time: Date) =>
                      handleDateTimeChange(time, "startTime")
                    }
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    onKeyDown={(e: any) => {
                      e.preventDefault();
                    }}
                    filterTime={(time: Date) => {
                      if (currentSettingForSelectedDay) {
                        const current = moment(time);
                        const start = moment()
                          .startOf("day")
                          .add(currentSettingForSelectedDay?.time, "minutes")
                          .add(-offset, "hour");
                        const end = moment()
                          .startOf("day")
                          .add(
                            currentSettingForSelectedDay.time +
                              currentSettingForSelectedDay.length,
                            "minutes"
                          )
                          .add(-offset, "hour");
                        const selectedEndTime = moment()
                          .startOf("day")
                          .add(selectedDay.endTime, "minutes")
                          .add(-offset, "hour");

                        return (
                          current.isBetween(start, end, undefined, "[]") &&
                          current.isBefore(selectedEndTime)
                        );
                      }
                    }}
                  />
                </div>
                <div className={`py-6 flex items-center`}>
                  <p className="flex-1">To</p>
                  <DatePicker
                    selected={moment()
                      .startOf("day")
                      .add(selectedDay.endTime, "minutes")
                      .add(-offset, "hour")
                      .toDate()}
                    //@ts-ignore
                    onChange={(time) => handleDateTimeChange(time, "endTime")}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={1}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    onKeyDown={(e: any) => {
                      e.preventDefault();
                    }}
                    filterTime={(time: Date) => {
                      if (currentSettingForSelectedDay) {
                        const current = moment(time);
                        const start = moment()
                          .startOf("day")
                          .add(currentSettingForSelectedDay?.time, "minutes")
                          .add(-offset, "hour");
                        const end = moment()
                          .startOf("day")
                          .add(
                            currentSettingForSelectedDay.time +
                              currentSettingForSelectedDay.length,
                            "minutes"
                          )
                          .add(-offset, "hour");
                        const selectedStartTime = moment()
                          .startOf("day")
                          .add(selectedDay.startTime, "minutes")
                          .add(-offset, "hour");

                        return (
                          current.isBetween(start, end, undefined, "[]") &&
                          current.isAfter(selectedStartTime)
                        );
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="mt-4">
            <Alert
              variant="info"
              message="Choose the times your guest can use Livly Key."
              hideIcon
            />
          </div>
          <div className="mt-4">
            <p className="font-medium text-lg">Available times</p>
            <p className="text-sm text-gray-500">
              These times are limited based on your property's settings.
            </p>
          </div>
          <div className="mt-4">
            {tempAccessControl?.schedule?.days?.map((day: DaySelected) => (
              <div
                className="flex items-center py-4 border-b w-full cursor-pointer group"
                key={day.day}
                onClick={() => {
                  setSelectedDayOfWeek(
                    convertFriendlyDayOfWeekToEnum(day.day)!
                  );
                }}
              >
                <div className="w-44">
                  <p className="text-lg text-gray-700 group-hover:text-red-300">
                    {getFriendlyDayOfWeek(day?.day)}
                  </p>
                </div>
                <div className="flex-1 flex justify-end text-gray-400 group-hover:text-red-300">
                  {day?.disabled ? (
                    <p>Off</p>
                  ) : (
                    <p>
                      {moment()
                        .startOf("day")
                        .add(day?.startTime, "minutes")
                        .format("LT")}{" "}
                      -{" "}
                      {moment()
                        .startOf("day")
                        .add(day?.endTime, "minutes")
                        .format("LT")}
                    </p>
                  )}
                  <div>
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="text-sm text-gray-400 mx-2 group-hover:text-red-300"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-4 flex justify-between gap-4">
            <Button color="default" onClick={handleReset}>
              reset
            </Button>
            <Button onClick={onDone}>Done</Button>
          </div>
        </>
      )}
      {/* <div className="flex flex-col justify-center p-2">
        <>
          {selected === 0 && !daySelected ? (
            <>
              <div className={` border-b py-4 flex  relative`}>
                <p className="flex-1">From</p>

                <DatePicker
                  selected={startDate}
                  //@ts-ignore
                  onChange={(time) => {
                    setStartDate(time);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  minTime={new Date(0, 0, 0, 0, 0)}
                  maxTime={endDate && getMaxMinutes()}
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              </div>
              <div className={` border-b py-4 flex  relative`}>
                <p className="flex-1">To</p>
                <DatePicker
                  selected={endDate}
                  //@ts-ignore
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  minTime={startDate && getMinMinutes()}
                  maxTime={new Date(0, 0, 0, 23, 59)}
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </>
          ) : selected === 1 && daySelected ? (
            <></>
          ) : null}
        </>
      </div> */}
    </Modal>
  );
};

const getTimezoneDifference = (
  timezone1: string,
  timezone2: string
): number => {
  const now = moment();
  const tz1Offset = now.tz(timezone1).utcOffset();
  const tz2Offset = now.tz(timezone2).utcOffset();
  return (tz1Offset - tz2Offset) / 60;
};

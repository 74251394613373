import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useGetMenuItems } from "../components/MainMenu";
import useLivlyUser from "../context/UserProvider";
import PollTask from "./polls/PollTask";
import OnboarderProgress from "@/components/OnboarderProgress";
import ResidentTasks from "@/components/ResidentTasks";

export default function HomePage() {
  const params = useParams<{ leaseId: string }>();

  const { user } = useLivlyUser();
  const menuItems = useGetMenuItems(user);

  return (
    <>
      <div className={`py-6 bg-gray-100 h-screen`}>
        <div className={`mx-auto max-w-7xl px-4 sm:px-6 md:px-8`}>
          <ResidentTasks />
          <OnboarderProgress />
          <p className="text-sm mt-4">Good afternoon,</p>
          <h1 className="text-2xl font-semibold text-gray-900 ">
            {user.firstName}
          </h1>
          <div className="grid grid-cols-6 gap-4 mt-4 md:grid-cols-12">
            {menuItems.map((item) => (
              <Link
                to={`/lease/${params.leaseId}/${item.to}`}
                key={item.to}
                className="col-span-3 p-4 bg-white rounded-lg shadow-md hover:bg-slate-50"
              >
                {item.image ? (
                  <img src={item.image} className="h-8 w-8" />
                ) : (
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                )}
                <p className="block mt-2 font-bold">{item.label}</p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
